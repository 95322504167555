import React from "react";
import Header from "../../components/Header";
import HomepageGrid from "../../components/HomepageGrid";
import Services from "../../components/Services";
import Disc from "../../components/Disc";
import About from "../../components/About";
import Contact from "../../components/Contact";

const HomeNew = () => {
  return (
    <>
      <Header />
      <HomepageGrid />
      <Services />
      <Disc />
      <About />
      <div className="container fluid pd-m">
        <p className="accent-dark font-2 -m">How are we changing the game?</p>
        <h1 className="font-1 heading xl">A radically different approach</h1>
        <div className="flex center space-between mt-5 approach">
          <div>
            <div className="circle flex center gap">
              <span></span>
            </div>
            <p className="font-1 -m" style={{ maxWidth: 400 }}>
              Whatsapp support
            </p>
          </div>
          <div>
            <div className="circle flex center gap">
              <span></span>
              <span></span>
            </div>
            <p className="font-1 -m" style={{ maxWidth: 400 }}>
              Smart Solutions with Experience Team
            </p>
          </div>
          <div>
            <div className="circle flex center gap">
              <span></span>
              <span></span>
              <span></span>
            </div>
            <p className="font-1 -m" style={{ maxWidth: 400 }}>
              All types PF & ESIC Services
            </p>
          </div>
        </div>
      </div>
      <Contact/>
    </>
  );
};

export default HomeNew;
