import React, { useState } from "react";
import "./css/Service.css";
import Buttons from "./Buttons.tsx";
import { Link, useNavigate } from "react-router-dom";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import Epf from '../img/epf-banner.png';
import Esic from '../img/esic-banner.png';
import Fact from '../img/fact-banner.png';

const Services = () => {
  const [imgHover, setImgHover] = useState();
  const [inView, setInView] = useState(false);
  const navigate = useNavigate();
  const regex =
    /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
  useGSAP(
    (context, contextSafe) => {
      gsap.to(".img-hover", {
        scrollTrigger: {
          trigger: ".services",
          start: "top bottom",
          end: "bottom top",
          onEnter: () => setInView(true),
          onLeave: () => setInView(false),
        },
      });

      gsap.set(".img-hover", { xPercent: -50, yPercent: -80 });

      let xTo = gsap.quickTo(".img-hover", "x", {
          duration: 0.2,
          ease: "power3",
        }),
        yTo = gsap.quickTo(".img-hover", "y", {
          duration: 0.2,
          ease: "power3",
        });

      const handleImgHover = contextSafe((e) => {
        xTo(e.clientX);
        yTo(e.clientY);
      });

      inView
        ? window.addEventListener("mousemove", handleImgHover)
        : window.removeEventListener("mousemove", handleImgHover);

      return () => window.removeEventListener("mousemove", handleImgHover);
    },
    { dependencies: [inView, navigator.userAgent] }
  );

  return (
    <section id="services" className="container fluid center">
      <div className={`img-hover ${imgHover ? "active" : ""}`}>
        <h1 className="font-1 -l">{imgHover}</h1>
        <div className="line"></div>
        <p className="font-1">Learn more</p>
      </div>
      <div className="services">
        <div className="heading xl font-1 text-light fw-light flex center">
          SERVICES{" "}
          <Buttons type="link-icon-complementary" style={{ marginLeft: 10 }} />
        </div>
        <div className="container fluid mt-1">
          <div
            className=" flex center border bottom services-img-container r pointer"
            onMouseOver={() => {
              setImgHover(regex.test(navigator.userAgent) ? null : "EPF");
            }}
            onMouseLeave={() => setImgHover()}
            onClick={() => navigate("/")}
          >
            <img src={Epf} alt="" />
          </div>
          <div className="border bottom pd-3">
            <h1 className="accent heading h2 fw-light font-1">01</h1>
            <h1 className="text-light heading xl mnt-2 mx-3 fw-light">EPF</h1>
            <p className="text-light mx-3 font-1 ">
              We handle everything from registration with the EPFO to timely
              contribution deposits, liaison for account management, and
              assistance with claims processing.
            </p>
            <br />
            <Link className="mx-3 font-1 " to="/">
              Learn more
            </Link>
          </div>
          <div
            className="flex center left border bottom r services-img-container pointer"
            onMouseOver={() => {
              setImgHover(regex.test(navigator.userAgent) ? null : "ESIC");
            }}
            onMouseLeave={() => setImgHover()}
            onClick={() => navigate("/")}
          >
            <img src={Esic} alt="" />
          </div>

          <div className="border bottom ">
            <h1 className="accent heading h2 fw-light font-1">02</h1>
            <h1 className="text-light heading xl mnt-2 mx-3 fw-light">ESIC</h1>
            <p className="text-light mx-3 font-1 ">
              Seamless registration with the ESIC, automated contribution
              calculations through payroll integration, medical claim support
              for employees and dependents, and ensuring compliance with medical
              facility regulations are all part of our service.
            </p>
            <br />
            <Link className="mx-3 font-1 " to="/">
              Learn more
            </Link>
          </div>
          <div
            className="flex center left border bottom responsive r services-img-container pointer"
            onMouseOver={() => {
              setImgHover(regex.test(navigator.userAgent) ? null : "FACT ACT");
            }}
            onMouseLeave={() => setImgHover()}
            onClick={() => navigate("/")}
          >
            <img src={Fact} alt="" />
          </div>
          <div className="flex column bottom responsive">
            <h1 className="accent heading h2 fw-light font-1">03</h1>
            <h1 className="text-light heading xl mnt-2 mx-3 fw-light">
              FACT ACT
            </h1>
            <p className="text-light mx-3 font-1 ">
              We guide you through factory registration, ensure adherence to
              workplace safety and health standards, assist with inspections,
              and support record maintenance and reporting.
            </p>
            <br />
            <Link className="mx-3 font-1 " to="/">
              Learn more
            </Link>
          </div>
        </div>
      </div>
      <div className="service-contact-followup">
        <h1 className="font-2 heading h1">WANT TO BE SUCCESSFUL?</h1>
        <div className="container fluid center">
          <a
            href="#contact"
            onClick={() => gsap.to(window, { scrollTo: "#contact" })}
            className="font-1"
          >
            BECOME OUR CLIENT
          </a>
        </div>
      </div>
    </section>
  );
};

export default Services;
