import React from "react";
import "./css/Disc.css";
import Img from "../img/funds2.webp";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Disc = () => {
  useGSAP(
    () => {
      gsap.registerPlugin(ScrollTrigger);

      gsap.set(".font-1", { y: 300 });

      gsap.to(".font-1", {
        scrollTrigger: {
          trigger: ".t1",
          end: "bottom 90%",
          scrub: 1,
          once: true,
        },
        stagger: 0.2,
        y: 0,
      });

      gsap.to(".disc > img", {
        scrollTrigger: {
          trigger: ".t1",
          end: "bottom 80%",
          start: "top 90%",
          scrub: 0,
        },
        ease: "none",
        rotate: "100deg",
      });
    },
    { scope: "#disc" }
  );

  return (
    <section id="disc" className="flex center justify-center">
      <div className="t1">
        <div className="flex center space-between mt-5">
          <div className="font-1 -m">
            By choosing us, you gain expertise, efficiency, and peace of mind.
            We save you time and resources with streamlined processes, ensure
            accuracy to avoid penalties, and handle compliance so you can focus
            on running your business.
          </div>
          <div className="disc flex center justify-center">
            <img src={Img} alt="" />
            <div className="inner-circle"></div>
          </div>
        </div>
        <div className="flex center space-between mt-5">
          <div className="disc flex center justify-center">
            <img src={Img} alt="" />
            <div className="inner-circle"></div>
          </div>
          <div className="font-1 -m">
            We offer flexible engagement models, keep you updated on regulation
            changes, and maintain complete data confidentiality.
          </div>
        </div>
        <div className="flex center space-between mt-5">
          <div className="font-1 -m">
            Employee benefits & compliance a headache? We fix that. EPF, ESIC,
            Factories Act - handled. Flexible, confidential service. You run
            your business, we handle compliance.
          </div>
          <div className="disc flex center justify-center">
            <img src={Img} alt="" />
            <div className="inner-circle"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Disc;
