import React from "react";
import Buttons from "./Buttons.tsx";

const Contact = () => {
  return (
    <section id="contact" className="container fluid center mt-5">
      <div className="contact">
        <div className="heading xl font-1 text-light fw-light flex center">
          CONTACT US{" "}
          <Buttons type="link-icon-complementary" style={{ marginLeft: 10 }} />
        </div>
        <div className="container fluid">
          <div className="input font-1 heading h1 fw-light">
            <input id="name" name="name" type="text" />
            <label htmlFor="name">
              <span className="accent">01 </span>Name
            </label>
          </div>
          <div className="input font-1 heading h1 fw-light">
            <input id="Phone" name="Phone" type="tel" />
            <label htmlFor="Phone">
              <span className="accent">02 </span>Phone
            </label>
          </div>
          <div className="input font-1 heading h1 fw-light">
            <input id="Email" name="Email" type="email" />
            <label htmlFor="Email">
              <span className="accent">03 </span>Email
            </label>
          </div>
          <div className="input font-1 heading h1 fw-light">
            <input id="Subject" name="Subject" type="text" />
            <label htmlFor="Subject">
              <span className="accent">04 </span>Subject
            </label>
          </div>
        </div>
        <div className="input font-1 heading h1 fw-light">
          <textarea id="Subject" name="Subject" type="text" />
          <label htmlFor="Subject">
            <span className="accent">05 </span>Message
          </label>
        </div>
        <div></div>
        <div className="flex center justify-center fluid mt-2">
          <button className="btn btn-primary">SEND</button>
        </div>
      </div>
    </section>
  );
};

export default Contact;
