import React, { useEffect, useState } from "react";
import { animated, useSpring, useSpringRef, useChain } from "@react-spring/web";
import "./css/Navbar.css";
import { Link, useLocation } from "react-router-dom";
import Trail from "./Trail";
import Map from "../img/world.svg";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";

const Navbar = () => {
  const [navActive, setNavActive] = useState(false);

  gsap.registerPlugin(ScrollToPlugin);

  //Animation hooks
  const navIconLeftRef = useSpringRef();
  const navIconLeftAnim = useSpring({
    ref: navIconLeftRef,
    from: { x: "-5px" },
    to: { x: navActive ? "0px" : "-5px" },
    config: { mass: 5, tension: 280, friction: 12 },
  });

  const navIconRightRef = useSpringRef();
  const navIconRightAnim = useSpring({
    ref: navIconRightRef,
    from: { x: "5px" },
    to: { x: navActive ? "0px" : "5px" },
    config: { tension: 280, friction: 12 },
  });

  const navIconMidRef = useSpringRef();
  const navIconMidAnim = useSpring({
    ref: navIconMidRef,
    from: { scale: "1" },
    to: { scale: navActive ? "7" : "1" },
    config: { tension: 280, friction: 12 },
  });

  const expandRef = useSpringRef();
  const expandAnim = useSpring({
    ref: expandRef,
    from: { x: "100%" },
    to: { x: navActive ? "0%" : "100%" },
    config: { tension: 120, friction: 30 },
  });

  const expandBlurRef = useSpringRef();
  const expandBlurAnim = useSpring({
    ref: expandBlurRef,
    from: { x: "100%" },
    to: { x: navActive ? "0%" : "100%" },
    config: { tension: 120, friction: 24 },
  });

  const navHeaderAfterRef = useSpringRef();
  const navHeaderAfterAnim = useSpring({
    ref: navHeaderAfterRef,
    from: { width: "0%" },
    to: { width: !navActive ? "0%" : "100%" },
    delay: navActive ? 500 : 0,
    config: { tension: 280, friction: 60 },
  });

  const navHeaderh1Ref = useSpringRef();
  const navHeaderh1Anim = useSpring({
    ref: navHeaderh1Ref,
    from: { y: "-100%" },
    to: { y: navActive ? "0%" : "-100%" },
    config: { tension: 180, friction: 12 },
  });

  const navHeaderpRef = useSpringRef();
  const navHeaderpAnim = useSpring({
    ref: navHeaderpRef,
    from: { opacity: 0 },
    to: { opacity: !navActive ? 0 : 1 },
    config: { tension: 210, friction: 20 },
  });

  const earthRef = useSpringRef();
  const earthAnim = useSpring({
    ref: earthRef,
    from: { opacity: 0 },
    to: { opacity: navActive ? 1 : 0 },
  });

  const addressRef = useSpringRef();
  const addressAnim = useSpring({
    ref: addressRef,
    from: { y: "150%" },
    to: { y: navActive ? "0%" : "150%" },
  });

  useChain(
    [
      navIconLeftRef,
      navIconMidRef,
      navIconRightRef,
      expandBlurRef,
      expandRef,
      navHeaderAfterRef,
      navHeaderh1Ref,
      navHeaderpRef,
      earthRef,
      addressRef,
    ],
    [
      navActive ? 0 : 0.9,
      navActive ? 0.2 : 0.8,
      navActive ? 0 : 0.9,
      !navActive ? 0.7 : 0.2,
      0.5,
      navActive ? 0.4 : 0.1,
      navActive ? 0.9 : 0.4,
      navActive ? 0.9 : 0.4,
      navActive ? 0.9 : 0.4,
      navActive ? 0.9 : 0.4,
    ]
  );

  const location = useLocation();

  useEffect(() => {
    setNavActive(false);
  }, [location.pathname]);

  return (
    <nav>
      {/* <div className="logo">
        
      </div> */}
      {window.innerWidth >= 890 ? (
        <ul className="nav-items flex center justify-center font-1">
          <li>
            <Link
              to="/"
              onClick={() => gsap.to(window, { scrollTo: "header" })}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="#services"
              onClick={() => gsap.to(window, { scrollTo: "#services" })}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="#about"
              onClick={() => gsap.to(window, { scrollTo: "#about" })}
            >
              About
            </Link>
          </li>
          <li>
            <Link
              to="#contact"
              onClick={() => gsap.to(window, { scrollTo: "#contact" })}
            >
              Contact
            </Link>
          </li>
        </ul>
      ) : (
        <></>
      )}

      <div className="icon-div">
        <div className="nav-expand" onClick={() => setNavActive(true)}>
          <animated.div style={navIconLeftAnim} className="dot"></animated.div>
          <animated.div style={navIconMidAnim} className="dot"></animated.div>
          <animated.div style={navIconRightAnim} className="dot"></animated.div>
        </div>
      </div>
      <animated.div style={expandBlurAnim} className="nav-blur"></animated.div>
      <animated.div style={expandAnim} className={`expand`}>
        {window.innerWidth > 850 ? (
          <section id="contactGlobe">
            <div className="container center hidden address-container">
              <animated.h1 style={addressAnim}>
                33 Anand Nagar, Lalbangla Kanpur-208007
              </animated.h1>
            </div>
            <animated.div style={earthAnim} className="earth">
              <div className="bg"></div>
              <div className="hor"></div>
              <div className="ver"></div>
              <div className="map">
                <img src={Map} alt={Map} />
                <img src={Map} alt={Map} />
                <img src={Map} alt={Map} />
                <img src={Map} alt={Map} />
              </div>
            </animated.div>
          </section>
        ) : (
          <div></div>
        )}

        <div>
          <div class="marquee">
            <div class={`track`}>
              <div class="content">
                {[...Array(10)].map(() => {
                  return <span>SAYYED KHURSHED ALAM CONSULTANCY </span>;
                })}
              </div>
            </div>
          </div>
          <div className="body">
            <div className="nav-header">
              <animated.span style={navHeaderAfterAnim}></animated.span>
              <div className="container">
                <animated.h1 style={navHeaderh1Anim}>KHURSHED</animated.h1>
                <animated.p style={navHeaderpAnim}>consultancy</animated.p>
              </div>
              <div className="container-2">
                <div className="close" onClick={() => setNavActive(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="search-icon"
                    style={{ fill: "black" }}
                  >
                    <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
                  </svg>
                </div>
              </div>
            </div>
            <div className="nav-links">
              <Trail open={navActive}>
                <span>
                  {"01 "}
                  <Link
                    to="/"
                    data-link="HOME"
                    onClick={() => {
                      gsap.to(window, { scrollTo: "" });
                      setNavActive(false);
                    }}
                  >
                    HOME
                  </Link>
                </span>
                <span>
                  {"02 "}
                  <Link
                    to="#services"
                    data-link="SERVICES"
                    onClick={() => {
                      gsap.to(window, { scrollTo: "#services" });
                      setNavActive(false);
                    }}
                  >
                    SERVICES
                  </Link>
                </span>
                <span>
                  {"03 "}
                  <Link to="/location" data-link="LOCATION">
                    LOCATION
                  </Link>
                </span>
                <span>
                  {"04 "}
                  <Link
                    to="#about"
                    data-link="ABOUT"
                    onClick={() => {
                      gsap.to(window, { scrollTo: "#about" });
                      setNavActive(false);
                    }}
                  >
                    ABOUT US
                  </Link>
                </span>
                <span>
                  {"05 "}
                  <Link
                    to="#contact"
                    data-link="CONTACT"
                    onClick={() => {
                      gsap.to(window, { scrollTo: "#contact" });
                      setNavActive(false);
                    }}
                  >
                    CONTACT
                  </Link>
                </span>
              </Trail>
            </div>
            <div className="nav-footer">
              <animated.span style={navHeaderAfterAnim}></animated.span>
              <animated.div style={navHeaderpAnim}>
                <span className="text-light">Follow us on</span>
                <div>
                  <a href="/instagram">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z"></path>
                      <circle cx="16.806" cy="7.207" r="1.078"></circle>
                      <path d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z"></path>
                    </svg>
                  </a>
                  <a href="/facebook">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M12.001 2.002c-5.522 0-9.999 4.477-9.999 9.999 0 4.99 3.656 9.126 8.437 9.879v-6.988h-2.54v-2.891h2.54V9.798c0-2.508 1.493-3.891 3.776-3.891 1.094 0 2.24.195 2.24.195v2.459h-1.264c-1.24 0-1.628.772-1.628 1.563v1.875h2.771l-.443 2.891h-2.328v6.988C18.344 21.129 22 16.992 22 12.001c0-5.522-4.477-9.999-9.999-9.999z"></path>
                    </svg>
                  </a>
                  <a href="/twitter">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                      <path d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z"></path>
                    </svg>
                  </a>
                </div>
                <div
                  style={{
                    fontSize: "0.8rem",
                    display: "block",
                    color: "var(--light)",
                    marginTop: "10px",
                  }}
                >
                  <span className="my-2 flex">&copy; KHURSHID CONSULTANCY</span>
                </div>
              </animated.div>
            </div>
          </div>
        </div>
      </animated.div>
    </nav>
  );
};

export default Navbar;
