import React from "react";
import "./css/About.css";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import Man from "../img/khurshed-1.jpg";
import Man2 from "../img/khurshed-3.jpg";


const About = () => {
  useGSAP(
    () => {
      gsap.fromTo(
        ".track1",
        { x: "0%" },
        { x: "-50%", ease: "none", duration: 100, repeat: -1 }
      );
    },
    { scope: "#about" }
  );

  return (
    <section id="about">
      <div
        className="text-marquee"
        style={{
          background: "var(--dark)",
          height: "60px",
        }}
      >
        <div
          className="track1"
          style={{
            fontSize: "3rem",
            height: "100px",
            "--time": "250s",
          }}
        >
          <div
            className="content"
            style={{
              overflowY: "hidden",
              height: "100px",
              color: "var(--light)",
            }}
          >
            {[...Array(20)].map(() => {
              return (
                <span style={{ marginLeft: 30 }}>
                  KHURSHED ALAM
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "var(--accent-complementary)",
                      textTransform: "uppercase",
                    }}
                  >
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width={50}
                      height={50}
                      fill="var(--accent)"
                      viewBox="0 0 122.88 122.88"
                      style={{
                        transform: "translateY(3px)",
                        marginLeft: 30,
                      }}
                      xmlSpace="preserve"
                    >
                      <g>
                        <path d="M62.43,122.88h-1.98c0-16.15-6.04-30.27-18.11-42.34C30.27,68.47,16.16,62.43,0,62.43v-1.98 c16.16,0,30.27-6.04,42.34-18.14C54.41,30.21,60.45,16.1,60.45,0h1.98c0,16.15,6.04,30.27,18.11,42.34 c12.07,12.07,26.18,18.11,42.34,18.11v1.98c-16.15,0-30.27,6.04-42.34,18.11C68.47,92.61,62.43,106.72,62.43,122.88L62.43,122.88z" />
                      </g>
                    </svg>
                  </span>
                </span>
              );
            })}
          </div>
        </div>
      </div>
      <div className="container fluid pd">
        <h1 className="font-1 heading xl fw-light mt-5">ABOUT ME</h1>
        <div className="line dark"></div>
        <div className="flex center justify-center p1">
          <div>
            <p className="accent-dark font-1 -l heading mt-5">Hey, I am</p>
            <h1 className="font-2 heading xl fw-light">KHURSHED</h1>
            <h1 className="font-2 heading xl fw-light">ALAM</h1>
          </div>
          <img src={Man} alt="" />
        </div>
        <div className="flex center justify-center p2">
          <div className="disc flex center justify-center">
            <img src={Man2} alt="" />
            {/* <div className="inner-circle"></div> */}
          </div>
          <div className="font-1 -m">
            The best consultant for PF, ESIC, and Fact act. With extensive
            experience, I ensure compliance and efficiency for businesses. Let's
            collaborate to optimize your operations and uphold legal standards.
          </div>
        </div>
        <div className="p3 container fluid center">
          <a href="mailto:khurshedalam1985@yahoo.in" className="font-1 -m heading fw-6">
          khurshedalam1985@yahoo.in
          </a>
          <a href="tel:9628744974" className="font-1 heading h1 mt-2">
            +91 962 874 4974
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
