import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import React from "react";
import "./css/Homegrid.css";
import Buttons from "./Buttons.tsx";
import useCounter from "../animations/counter";

const HomepageGrid = () => {
  gsap.registerPlugin(ScrollTrigger);

  useCounter();

  useGSAP(() => {
    gsap.to(".track1", {
      x: "-7%",
      ease: "none",
      scrollTrigger: {
        trigger: "#homeGrid",
        start: "top 90%",
        scrub: true,
        end: "bottom bottom",
      },
    });

    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".testimonial",
          start: "top 70%",
        },
      })
      .counter(
        ".num.a",
        {
          end: 100,
          increment: 10,
          duration: 0.3,
          ease: "circle.inOut",
        },
        0
      )
      .counter(
        ".num.b",
        {
          end: 50,
          increment: 5,
          duration: 0.3,
          ease: "circle.inOut",
        },
        0
      )
      .fromTo(
        ".grid-last > p",
        {
          opacity: 0,
          y: 100,
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.3,
          ease: "circ.out",
          stagger: 0.2
        },
        0
      );
  });

  return (
    <section id="homeGrid" className="container fluid hidden">
      <div
        className="text-marquee"
        style={{
          background: "var(--dark)",
          height: "60px",
        }}
      >
        <div
          className="track1"
          style={{
            fontSize: "3rem",
            height: "100px",
            "--time": "250s",
          }}
        >
          <div
            className="content"
            style={{
              overflowY: "hidden",
              height: "100px",
              color: "var(--light)",
            }}
          >
            {[...Array(20)].map(() => {
              return (
                <span style={{ marginLeft: 30 }}>
                  SAYYED KHURSHED ALAM
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "var(--accent-complementary)",
                      textTransform: "uppercase",
                    }}
                  >
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width={50}
                      height={50}
                      fill="var(--accent)"
                      viewBox="0 0 122.88 122.88"
                      style={{
                        transform: "translateY(3px)",
                        marginLeft: 30,
                      }}
                      xmlSpace="preserve"
                    >
                      <g>
                        <path d="M62.43,122.88h-1.98c0-16.15-6.04-30.27-18.11-42.34C30.27,68.47,16.16,62.43,0,62.43v-1.98 c16.16,0,30.27-6.04,42.34-18.14C54.41,30.21,60.45,16.1,60.45,0h1.98c0,16.15,6.04,30.27,18.11,42.34 c12.07,12.07,26.18,18.11,42.34,18.11v1.98c-16.15,0-30.27,6.04-42.34,18.11C68.47,92.61,62.43,106.72,62.43,122.88L62.43,122.88z" />
                      </g>
                    </svg>
                  </span>
                </span>
              );
            })}
          </div>
        </div>
      </div>

      <div className="home-grid">
        <div className="font-1 -m">
          <p>
            Our mission is to empower businesses with{" "}
            <span className="accent-dark">
              {" "}
              comprehensive financial and regulatory solutions
            </span>
            , enabling them to thrive in a dynamic and competitive environment.
          </p>
        </div>
        <div>
          <Buttons type="link" text="SERVICES" procedure={()=>gsap.to(window, {scrollTo: '#services'})} />{" "}
        </div>
        <div>
          <Buttons type="link" text="ABOUT US" procedure={()=>gsap.to(window, {scrollTo: '#about'})}/>
        </div>
        <div>
          <div className="testimonial">
            <h1
              className="font-1 heading fw-4"
              style={{ placeSelf: "flex-start" }}
            >
              WORKED WITH
            </h1>
            <h1>
              {" "}
              <span className="num a"></span> <span>+</span>
            </h1>
            <p
              className="accent-dark font-1 -l heading fw-4"
              style={{ placeSelf: "flex-end" }}
            >
              COMPANIES
            </p>
          </div>
        </div>
        <div>
          <div className="testimonial">
            <h1
              className="font-1  heading fw-4"
              style={{ placeSelf: "flex-start" }}
            >
              YEARS OF
            </h1>
            <h1>
              {" "}
              <span className="num b"></span> <span>+</span>
            </h1>
            <p
              className="accent-dark font-1 -l heading fw-4"
              style={{ placeSelf: "flex-end" }}
            >
              EXPERIENCE
            </p>
          </div>
        </div>
        <div>
          <div className="grid-last container hidden">
            <p>WE ARE </p>
            <p> THE <span className="accent-dark">EXPERTS</span></p> <p>IN THIS FIELD</p>
          </div>
        </div>
      </div>
      <div style={{height: 100}}></div>
    </section>
  );
};

export default HomepageGrid;
