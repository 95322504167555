import React from "react";
import Buttons from "../../components/Buttons.tsx";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <section id="contact-page">
      <div className="container fluid center">
        <h1 className="heading xl contact-hello" style={{ fontSize: "6rem" }}>
          HELLO
        </h1>
      </div>
      <div
        className="container relative fluid mt-5 round mh-4 bg-light fluid-h hidden"
        style={{ border: "1px solid var(--dark-low)" }}
      >
        <div
          className="text-marquee"
          style={{
            height: "50px",
            top: 0,
            left: 0,
            position: 'absolute',
          }}
        >
          <div
            className="track1"
            style={{
              fontSize: "2.5rem",
              fontWeight: 'lighter',
              height: "100px",
              "--time": "180s",
            }}
          >
            <div
              className="content"
              style={{
                overflowY: "hidden",
                height: "100px",
                color: "var(--light)",
              }}
            >
              {[...Array(20)].map(() => {
                return (
                  <span>
                    KHURSHED ALAM {" "}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
        <div className="container fluid hidden">
          <h1
            className="contact-heading heading h1 "
            style={{ fontFamily: "chivo", fontWeight: "lighter" }}
          >
            GET IN TOUCH <span className="hr"></span>
          </h1>
        </div>

        <form>
          <p className="contact-subtitle">
            {" "}
            WE’RE OPEN TO TALK TO GOOD PEOPLE. JUST SAY HELLO AND WE’LL START A
            PRODUCTIVE COOPERATION.
            <br />
            <br />
            CALL US: <a href="tel:0123456789">+91 012 345 6789</a> <br />
            EMAIL: <a href="mailto:test@123.com">test@123.com</a>
            <br />
            <br />
            <div className="logo">
              <Link to="/">
                <i className="bx bxl-instagram"></i>
              </Link>
              <Link to="/">
                <i className="bx bxl-facebook"></i>
              </Link>
              <Link to="/">
                <i className="bx bxl-twitter"></i>
              </Link>
            </div>
          </p>
          <input type="text" placeholder="NAME" />
          <input type="text" placeholder="PHONE" />
          <input type="text" placeholder="EMAIL" />
          <textarea type="text" placeholder="MESSAGE" />
          <div className="container fluid flex right">
            <Buttons type={"send"} text={["send"]}>
              <i
                style={{ fontSize: "4rem", rotate: "135deg" }}
                className="bx bxs-send"
              ></i>
            </Buttons>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Contact;
