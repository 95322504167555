import "./css/Button.css";
import React, {ReactNode, MouseEvent} from "react";

type ButtonProps = {
  type?:"link" | "link-icon-complementary";
  text?:string;
  procedure?:(event: MouseEvent<HTMLDivElement>) => void;
  children?:ReactNode;
} & JSX.IntrinsicElements['div']

const Buttons: React.FC<ButtonProps> = ({ type="", text = "", procedure, children, ...props }) => {
  switch(type){
    case 'link':
      return(
        <span className="btn btn-link" onClick={procedure} {...props}>
          <i className='bx bx-right-arrow-alt'></i>
          <i data-b className='bx bx-right-arrow-alt'></i>
          <p>{text}</p>
        </span>
      );
    case 'link-icon-complementary':
      return(
        <span className="btn btn-link-icon-complementary" onClick={procedure} {...props}>
          <i className='bx bx-right-arrow-alt a'></i>
          <i className='bx bx-right-arrow-alt b'></i>
        </span>
      );
    default:
      return <></>
  }
};

export default Buttons;
