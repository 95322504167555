import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Contact from "./pages/Contact";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import HomeNew from "./pages/HomeNew";

const Layout = () => {
  gsap.registerPlugin(ScrollToPlugin);

  const location = useLocation();

  useEffect(() => {
    gsap.to(window, { scrollTo: { y: 0 } });
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<HomeNew />} />
      
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
};

export default Layout;
