import Navbar from "./components/Navbar";
import "./css/App.css";
import "./css/import.css";
import "./css/Animations.css";
import "./css/Utils.css";
import Layout from "./layout/Layout";
import "./css/Colors.css";
import Footer from "./components/Footer";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

function App() {

  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

  return (
    <>
      <Navbar />
      <Layout />
      <Footer/>
    </>
  );
}

export default App;
