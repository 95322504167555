import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Footer = () => {
//   useGSAP(() => {
//     gsap.registerPlugin(ScrollTrigger);

//     gsap.to()
//   });
  return (
    <footer className="container fluid center">
     <p>&copy; KHURSHED ALAM</p>
    </footer>
  );
};

export default Footer;
